import React from "react";

import { Block, TrackedLink } from "../index";

import "./personality-block.scss";

const PersonalityBlock = ({ data }) => {
  const { name, imageSrc } = data;
  const refferalUrl = "https://app.partie.com/referral/" + data.code

  return (
    <Block className="personality-block" hero lazy>
      <div className="partie-block__column">
        <h2 className="partie-block__title">
          {name} wants you to join the Partie.
        </h2>
        <p className="partie-block__description">
          Quit waiting and start playing — Partie will help you find the perfect
          squad to accomplish your in-game goals, unlock achievements and even
          earn revenue. Sign up today and let the games begin.
        </p>
        <div className="partie-block__cta">
          <TrackedLink type="btn" to={refferalUrl} children="Join now" />
        </div>
      </div>
      <div className="partie-block__column">
        <img src={imageSrc} alt={name} />
      </div>
    </Block>
  );
};

export default PersonalityBlock;
